@font-face {
  font-family: Farnham;
  src: url("/vendor/fonts/farnham/FarnhamDisp-BlackOSF.otf");
  font-weight: 900;
}

@font-face {
  font-family: Farnham;
  src: url("/vendor/fonts/farnham/FarnhamDisp-BoldOSF.otf");
  font-weight: 800;
}

@font-face {
  font-family: Farnham;
  src: url("/vendor/fonts/farnham/FarnhamDisp-LightOSF.otf");
  font-weight: 300;
}

@font-face {
  font-family: Farnham;
  src: url("/vendor/fonts/farnham/FarnhamDisp-MedOSF.otf");
  font-weight: 600;
}

@font-face {
  font-family: Farnham;
  src: url("/vendor/fonts/farnham/FarnhamDisp-RegOSF.otf");
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: url("/vendor/fonts/avenir/avenirltstd-black-webfont.ttf");
  font-weight: 900;
}

@font-face {
  font-family: Avenir;
  src: url("/vendor/fonts/avenir/avenirltstd-heavy-webfont.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Avenir;
  src: url("/vendor/fonts/avenir/avenirltstd-light-webfont.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: url("/vendor/fonts/avenir/avenirltstd-book-webfont.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Avenir;
  src: url("/vendor/fonts/avenir/avenirltstd-medium-webfont.ttf");
  font-weight: 500;
}
