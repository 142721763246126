.logo {
  max-height: 50px;

  &.big {
    max-height: 100px;
  }
}

.logo-footer {
  max-height: 100px;
}

section {
  position: relative;
  padding: 5rem 0;

  &.intro {
    padding: 5rem 0 8rem;
  }

  &.padding-sm {
    padding: 3rem 0;
  }

  &.padding-lg {
    padding: 7rem 0;
  }

  &.pb0 { padding-bottom: 0; }

  @media (max-width: 991px) {
    padding: 3rem 0;
  }
}

.nav-section-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hero {
  padding: 8rem 0;
  min-height: 450px;
  display: flex;
  align-items: center;
  text-align: center;
}

.section-title {
  margin-bottom: 2rem;
}

.overlay {
  background-color: rgba(28, 27, 26, 0.25);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  &.strong {
    background-color: rgba(28, 27, 26, 0.75);
  }
}

.buttons {
  .button { margin: 0 8px 0 0; }
  .note { margin-top: 5px; font-size: 0.8rem; }

  @media (max-width: 991px) {
    .button {
      margin: 0 0 1rem;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.separator-right-lg {
  @media(min-width: 992px) {
    border-right: 4px solid $primary-text;
    padding-right: 1.5rem;
  }
}

.bg-hero-intro {
  background: no-repeat center 30%;
  background-size: cover;
  background-image: url(../img/bg-hero-world.jpg);
}

.bg-forest-intro {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest-intro.jpg);
}

.bg-city-1 {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/city1.jpg);
}

.bg-city-2 {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/city2.jpg);
}

.bg-collage {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/collage3.jpg);
}

.bg-cta-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-about.jpg);
}

.bg-home-frustrated {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-home-frustrated.jpg);
}

.bg-action {
  background: no-repeat 50% 30%;
  background-size: cover;
  background-image: url(../img/bg-action.jpg);
}

.bg-promise-home {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-promise-home.jpg);
}

.bg-cbf {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cbf.jpg);
}

.bg-parents {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-parents.jpg);
}

.bg-campus {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-campus.jpg);
}

.bg-elementary-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-elementary.jpg);
}

.bg-forest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest.jpg);
}

.bg-registration {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-registration.jpg);
}

.bg-resources {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-resources.jpg);
}

.bg-nest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-nest-hero.jpg);
}

.bg-middle {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-cta-community-middle {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-community-middle.jpg);
}

.bg-apply-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-apply-hero.jpg);
}

.bg-different {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-different.jpg);
}

.bg-cta-community-elementary {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cta-community-elementary.jpg);
}

.bg-parents-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/journey-3.jpg);
}

.bg-careers {
  background: no-repeat top center;
  background-size: cover;
  background-image: url(../img/bg-careers.jpg);
}

.testimonial {
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 300;

  .content {
    font-weight: 300;
  }

  .attribution {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.5rem;
  }

  &.white-letters { color: white; .attribution { color: white;} }

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.play {
  max-height: 80px;
}

.signature {
  max-height: 60px;
  max-width: 180px;
}

.editorial {
  text-align: left;

  &.center { text-align: center; }

  h3 {
    font-size: 1.75rem;
  }

  p {
    font-weight: 300;
    font-size: 15px;
    color: $black;
    line-height: 1.62;
  }
}

.box {
  padding: 30px;
}

.faqs-box {
  text-align: left;
  padding: 0 30px;
  margin: 3rem 0;

  .question-wrapper {
    padding: 30px 0;
    border-bottom: 1px solid rgba(black, 0.075);

    &.active .question::after { background-image: url(../img/icon-chevron-up.png); }
  }

  .question {
    color: $secondary;
    font-weight: 300;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: auto;
      padding-left: 50px;
      display: block;
      position: relative;
      width: 100%;
      max-width: 21px;
      height: 11px;
      background-image: url(../img/icon-chevron-down.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .answer {
    display: none;
    font-size: 0.9rem;
    margin-top: 1rem;
    line-height: 1.72;
    p { color: $black; font-weight: 300; }
    p:last-child { margin: 0; }
  }
}

.team-member {
  max-height: 80px;
  border-radius: 50%;
}

.icon {
  max-height: 80px;
  height: 100%;
  width: auto;
}

footer {
  background-color: white;;
  padding: 5rem 0;
  border-top: 1px solid rgba(black, 0.075);

  a {
    color: $grey;
    display: inline-block;
    margin: 1rem;
    transition: 200ms all;

    &:hover, &:focus, &:active {
      color: lighten($secondary, 15%);
      text-decoration: none;
    }

    @media (max-width: 991px) {
      display: block;
    }
  }

  .copyright {
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.social-icons {
  margin-bottom: 3rem;;
  .social-icon {
    display: inline-block;
    margin: 0 3px;
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 18px;
    text-align: center;
    color: white;
    background: $grey;
    border-radius: 50%;
    transition: 200ms all;

    &:hover {
      background: lighten($blue, 10%);
    }
  }
}

.contact-card {
  display: flex;
  align-items: center;
  font-weight: 300;
  img { margin-right: 1rem; }

  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: $primary;
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.feature {
  text-align: left;
  h5 {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.72;
  }
}

.img-mega-wrapper {
  position: relative;
  margin-top: 40px;
  img {
    position: absolute;
    right: 0;
  }

  @media(max-width: 991px) {
    display: none;
  }
}

.img-featured {
  max-height: 450px;
}

.parents-club-books {
    a {
        font-weight: 700;
        text-decoration: underline;
    }
}

.parents-club-video {
    overflow: hidden;
    iframe {
        background-color: black;
        width: 100%;
        height: 310px;
    }

    p {
        text-align: center;
    }

    &.col-md-4{
        iframe{
            height: 200px;
        }
    }

}

.person-title {
  margin-top: 3px;
  font-size: 0.66em;
  color: $grey;
}

.person-wrapper {
  padding: 3rem 0;
}

.tools-images {
  @media (max-width: 991px) {
    img {
      max-height: 80px;
      max-width: 180px;
    }
  }
}

.navigation-box {
  position: relative;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 200ms all;

  .box-header {
    padding: 2rem 30px 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .box-body {
    padding: 1rem 30px;
    p { font-size: 1rem; }
    p:last-child { margin-bottom: 0; }
  }

  .box-footer {
    margin-top: auto;
    padding: 1rem 30px 2rem;
  }

  &:hover, &:focus, &:active {
    transform: translateY(-5px);
  }
}

.location-row {
  [class*=' col-'], .col-md-4 {
    min-height: 400px;
  }

  .box {
    padding: 5rem 30px;
    height: 100%;
  }
}

.learning-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 0;

  .learning-box {
    display: inline-block;
    vertical-align: top;
    margin: 4px;
    max-width: 400px;
    border-radius: 3px;
    overflow: hidden;

    img {
      max-width: 100%;
      height: 310px;
    }

    .learning-box-body {
      padding: 30px;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 600;
    }

    h5 {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      margin-left: 8px;
    }

    hr {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 0;
      border-top: 4px solid white;
      max-width: 30px;
      &.black { border-top-color: $black; }
    }

    .highlight {
      font-size: 1.25rem;
      position: relative;
      text-align: center;
      margin: 1rem 0;
    }

    .underline {
      display: inline-block;
    }

  }
}

.box-event {
  padding: 30px;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 4px;

  .title {
    font-weight: 600;
  }

  .button {
    margin-bottom: 0;
  }
}

.schedule {
  text-align: left;

  .time-slot {
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    border: dashed 2px $tertiary;
    margin-bottom: 8px;
    color: $tertiary;

    .time {
      width: 75px;
    }
    .description {
      width: 100%;
    }

    &.solid {
      background-color: $primary;
      color: white;
      border: 2px solid $primary;
    }

    &.red {
      background-color: $quaternary;
      border-color: $quaternary;
      color: $black;
    }

    &.bg-light-blue {
      border: 2px solid $secondary;
      background-color: $secondary;
    }
  }
}

.deconstructed-logo {
  width: 100%;

  .part {
    position: relative;
    max-width: 50%;
    margin-left: 0;
    transition: 200ms all;

    img {
      max-width: 100%;
    }

    .arrow {
      position: absolute;
      right: 10px;
      bottom: 0;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
    }

    &:hover {
      cursor: pointer;
      margin-left: 20px;
    }

    &.active {
      margin-left: 50%;

      .arrow {
        display: none;
      }

      .content {
        display: block;
      }

      &:hover { margin-left: calc(50% - 20px);}
    }

    &:first-child { margin-bottom: -14%;}
    &:nth-child(2) { margin-bottom: -10%;}
    &:nth-child(3) { margin-bottom: -5%;}
    &:nth-child(4) { margin-bottom: -3%;}
  }
}

.deconstructed-logo-content {
  display: flex;
  justify-content: flex-end;

  h2 {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .content {
    max-width: 500px;
    display: none;

    &.active {
      display: flex;
      .icon { margin-right: 2rem; }
    }
  }
}

.school-comparison {
  ul {
    padding-top: 2rem;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      height: 100%;
      width: 4px;
      background-color: $secondary;
      position: absolute;
      top: -1px;
      left: 0px;
      display: block;
    }

    li {
      position: relative;
      display: block;
      margin: 0.5rem 0;
      transition: 200ms all;

      &::before {
        content: "";
        width: 100%;
        height: 4px;
        background-color: $secondary;
        position: absolute;
        display: block;
        left: -50%;
        top: 50%;
        transform: translateY(-50%);
        z-index: -10;
      }

      .content {
        z-index: 10;
        padding: 10px;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        min-height: 65px;

        @media(max-width: 991px) {
          min-height: 120px;
          font-size: 0.8rem;
        }
      }

      &:hover {
        transform: translateX(10px);
      }

      &:last-child {
        &::after {
          content: "";
          top: calc(50% + 2px);
          width: 100px;
          height: 100%;
          left: -100px;
          background: white;
          position: absolute;
        }
      }
    }
  }
}

.img-cont-16-9{
  width: 100%;
  height: 0;
  box-sizing: content-box;
  position: relative;
  padding-top: 56.25%;

  img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video-link .img-cont-16-9{
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: rgba(0,0,0,.3) url(../img/videos/icon-play.png) no-repeat center center;
    background-size: 60px 60px;
    opacity: 0;
    transition: .2s ease-in opacity;
  }
  &:hover{
    &:after{opacity: 1;}
  }
}

.application-intro-text{
  p{
    line-height: 1.35 !important;

    span{
      background: rgba(#efe0a4, .4);
    }
  }
}

.collapse-form-heading{
  display: block;
  padding: .5rem 2rem;
  border-radius: .25rem;
  text-decoration: none;
  margin-bottom: 0;
  margin-top: 2px;

  &[aria-expanded=true]{
    border-radius: .25rem .25rem 0 0;
    h3:before{
      content: "\f107";
    }
  }

  &:hover{
    text-decoration: none;
  }

  h3{
    margin: 0;
    color: #fff;
    &:before{
      content: "\f105";
      margin-right: .5rem;
      font-family: fontAwesome;
    }
  }

  &.section3 h3{
    color: $tertiary;
  }

  &.section1{
    background: $primary;
  }
  &.section2{
    background: $tertiary;
  }
  &.section3{
    background: $quaternary;
  }
  &.section4{
    background: $secondary;
  }
}

.collapse-section {
  padding: 1rem 2rem;

  &.section1{
    background: rgba($primary, .2);
  }
  &.section2{
    background: rgba($tertiary, .2);
  }
  &.section3{
    background: rgba($quaternary, .4);
  }
  &.section4{
    background: rgba($secondary, .2);
  }
}

.uploader-button {
  margin-right: 10px !important;
}
.hint {
  font-size: 14px;
}

.pb-blog-post-card:last-child {
  border: 0;
}

.pill {
  display: inline-block;
  padding: 3px 12px;
  background-color: $secondary;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 16px;
}